import React from "react";
import Header from "../../components/Header";
import TableOperation from "../../components/TableOperation";

type OperationProps = {
  test?: string;
};

export default function Operation({ test }: OperationProps) {
  return (
    <React.Fragment>
      <Header />
      <TableOperation />
    </React.Fragment>
  );
}
