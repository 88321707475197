/* eslint-disable */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Table,
  TableHeader,
  TableBody,
  Tr,
  Th,
  Td,
  WaterMarkFloating
} from './styles'
import CleanIcon from '../../assets/icons/clean.svg'
import FuelTankIcon from '../../assets/icons/fuel-tank.svg'
import IceCubeIcon from '../../assets/icons/ice-cube.svg'
import O2Icon from '../../assets/icons/o2.svg'
import PulidoraIcon from '../../assets/icons/pulidora.svg'
import QtaIcon from '../../assets/icons/qta.svg'
import QtuIcon from '../../assets/icons/qtu.svg'
import StewardessIcon from '../../assets/icons/stewardess.svg'
import WaterMarkIcon from '../../assets/images/watermark.svg'
import axios from 'axios'
import { useCallback } from 'react'

type TableOperatonProps = {
  test?: string
}

enum DateOperationENUM {
  RED_ALERT = 'red-alert',
  YELLOW_ALERT = 'yellow-alert',
  NONE = ''
}

export default function TableOperation({ test }: TableOperatonProps) {
  const [listServices, setListServices] = useState([])

  const dateAndTimeComparison = (dateToCompare: any): DateOperationENUM => {
    const comparisonResult =
      Math.abs(new Date().getTime() - new Date(dateToCompare).getTime()) /
      3600000

    if (comparisonResult <= 3 && comparisonResult > 1) {
      return DateOperationENUM.YELLOW_ALERT
    } else if (comparisonResult <= 1) {
      return DateOperationENUM.RED_ALERT
    } else {
      return DateOperationENUM.NONE
    }
  }

  const loadServices = useCallback(() => {
    axios
      .get(
        'https://api.jhsf.app/api/operation/find_all?withRelation=client&withRelation=service_order&withRelation=landing_takeoff&withRelation=different_operator_billing&withRelation=gendec'
      )
      .then((response) => {
        const {
          data: { data }
        } = response
        setListServices(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    loadServices()
  }, [])

  useEffect(() => {
    setInterval(() => {
      loadServices()
    }, 60000)
  }, [])

  return (
    <Table>
      <TableHeader>
        <Tr>
          <Th>Nº OS</Th>
          <Th>Prefixo</Th>
          <Th>Modelo</Th>
          <Th>Rampa</Th>
          <Th>ETA</Th>
          <Th>ETD</Th>
          <Th>Procedência</Th>
          <Th>Destino</Th>
          <Th>Serviços</Th>
        </Tr>
      </TableHeader>
      <TableBody>
        <WaterMarkFloating src={WaterMarkIcon} />
        {listServices &&
          listServices.map((item: any) => {
            const dateValidateArrival = moment(
              moment().format('YYYY-MM-DD')
            ).diff(
              moment(item?.landing_takeoff[0]?.estimated_time_arrival).format(
                'YYYY-MM-DD'
              )
            )
            const dateValidateDeparture = moment(
              moment().format('YYYY-MM-DD')
            ).diff(
              moment(item?.landing_takeoff[0]?.estimated_time_departure).format(
                'YYYY-MM-DD'
              )
            )
            const dateValidateRamp = moment(moment().format('YYYY-MM-DD')).diff(
              moment(item?.landing_takeoff[0]?.ramp_at).format('YYYY-MM-DD')
            )

            const checkHoras = moment(moment().format('YYYY-MM-DD')).diff(
              moment(item?.landing_takeoff[0]?.ramp_at).format('YYYY-MM-DD')
            )

            if (
              dateValidateArrival <= 0 ||
              dateValidateDeparture <= 0 ||
              dateValidateRamp <= 0
            )
              return (
                <Tr>
                  <Td>{item?.service_order?.numberOS}</Td>
                  <Td>{item?.client?.prefix}</Td>
                  <Td>{item?.client?.model}</Td>
                  <Td
                    className={item?.landing_takeoff[0]?.ramp_at && dateAndTimeComparison(
                      moment(item?.landing_takeoff[0]?.ramp_at)
                    )}
                  >
                    {item?.landing_takeoff[0]?.ramp_at ? moment(item?.landing_takeoff[0]?.ramp_at).format(
                      'DD/MM/YYYY - HH:mm'
                    ) : ' - '}
                  </Td>
                  <Td className={item?.landing_takeoff[0]?.estimated_time_arrival && dateAndTimeComparison(
                      moment(item?.landing_takeoff[0]?.estimated_time_arrival)
                    )}>
                    {item?.landing_takeoff[0]?.estimated_time_arrival ? moment(
                      item?.landing_takeoff[0]?.estimated_time_arrival
                    ).format('DD/MM/YYYY - HH:mm') : ' - '}
                  </Td>
                  <Td className={item?.landing_takeoff[0]?.estimated_time_departure && dateAndTimeComparison(
                      moment(item?.landing_takeoff[0]?.estimated_time_departure)
                    )}>
                    {item?.landing_takeoff[0]?.estimated_time_departure ? moment(
                      item?.landing_takeoff[0]?.estimated_time_departure
                    ).format('DD/MM/YYYY - HH:mm') : ' - '}
                  </Td>
                  <Td>{item?.origin}</Td>
                  <Td>{item?.destin}</Td>
                  <Td className="icons-column">
                    <img src={CleanIcon} alt="icon" />
                    <img src={FuelTankIcon} alt="icon" />
                    <img src={IceCubeIcon} alt="icon" />
                    <img src={QtuIcon} alt="icon" />
                    <img src={QtaIcon} alt="icon" />
                    <img src={PulidoraIcon} alt="icon" />
                    <img src={StewardessIcon} alt="icon" />
                    <img src={O2Icon} alt="icon" />
                  </Td>
                </Tr>
              )
          })}
      </TableBody>
    </Table>
  )
}
