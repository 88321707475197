import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import GlobalStyle from "./styles/global";

function App() {
  return (
    <React.Fragment>
      <GlobalStyle />
      <Router>
        <Routes />
      </Router>
    </React.Fragment>
  );
}

export default App;
