import styled from "styled-components";
import {
  tableHeaderBackground,
  fontColorSecondary,
  redAlertColor,
  yellowAlertColor
} from "../../styles/colors";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  max-width: 1920px;
`;

export const TableHeader = styled.thead`
  background-color: ${tableHeaderBackground};
  font-size: 30px;

  tr th {
    padding: 24px;
  }
`;

export const TableBody = styled.tbody`
  position: relative;
  tr {
    border-bottom: solid 1px ${fontColorSecondary};
  }
`;

export const Tr = styled.tr``;

export const Th = styled.th``;

export const Td = styled.th`
  padding: 24px;
  font-size: 26px;

  &.red-alert {
    background-color: ${redAlertColor};
    animation: pingAnimation 1s infinite;
  }

  &.yellow-alert {
    background-color: ${yellowAlertColor};
    animation: pingAnimation 1s infinite;
  }

  &.icons-column {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 150px;

    img {
      margin: 4px;
    }
  }

  @keyframes pingAnimation {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
    }
  }
`;

export const WaterMarkFloating = styled.img`
  position: fixed;
  bottom: 20px;
  right: 20px;
`;
