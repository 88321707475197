import { useState } from "react";

import {
  Container,
  LeftContent,
  RightContent,
  H1,
  AlertsWrapper,
  YellowAlert,
  RedAlert,
  Hour,
  ExtensiveDate
} from "./styles";
import { getCurrentHour, getCurrentDate } from "../../utils/DateUtils";

type HeaderProps = {
  test?: string;
};

export default function Header({ test }: HeaderProps) {
  const [currentHour, setCurrentHour] = useState("");
  const today = getCurrentDate();

  const updateTime = () => {
    setCurrentHour(getCurrentHour());
  };

  setInterval(updateTime, 1000);

  return (
    <Container>
      <LeftContent>
        <H1>Ordens de Serviço</H1>

        <AlertsWrapper>
          <YellowAlert>
            <div /> Alerta de Horário: Faltam 3 Horas
          </YellowAlert>

          <RedAlert>
            <div /> Alerta de Horário: Falta 1 Hora
          </RedAlert>
        </AlertsWrapper>
      </LeftContent>

      <RightContent>
        <Hour>{currentHour}</Hour>
        <ExtensiveDate>{today}</ExtensiveDate>
      </RightContent>
    </Container>
  );
}
