//Cores padrão do projeto

export const backgroundColorDefault = "#4a4a4a";

export const fontColorDefault = "#f1f1f1";

export const fontColorSecondary = "#9ca69c";

export const primaryGreen = "#9eb149";

export const yellowAlertColor = "#d99a0a";

export const redAlertColor = "#cb5957";

export const tableHeaderBackground = "#2b2827";
