import styled from "styled-components";
import {
  yellowAlertColor,
  redAlertColor,
  fontColorSecondary
} from "../../styles/colors";
import BgHeader from "../../assets/images/bg-header.png";

export const Container = styled.div`
  width: 100%;
  height: 240px;
  background-image: url(${BgHeader});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 50px;

  width: 600px;
  height: 100%;
  // background-image: linear-gradient(
  //   to right,
  //   rgba(43, 40, 39),
  //   rgba(43, 40, 39, 1),
  //   rgba(43, 40, 39, 0.5),
  //   rgba(43, 40, 39, 0.3),
  //   rgba(43, 40, 39, 0)
  // );
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-right: 50px;

  width: 600px;
  height: 100%;
  // background-image: linear-gradient(
  //   to left,
  //   rgba(43, 40, 39),
  //   rgba(43, 40, 39, 1),
  //   rgba(43, 40, 39, 0.5),
  //   rgba(43, 40, 39, 0.3),
  //   rgba(43, 40, 39, 0)
  // );
`;

export const H1 = styled.h1`
  font-size: 42px;
`;

export const AlertsWrapper = styled.div`
  width: 100%;
  height: 80px;
  color: ${fontColorSecondary};
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const YellowAlert = styled.div`
  display: flex;
  align-items: center;

  div {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${yellowAlertColor};
    margin-right: 10px;
  }
`;

export const RedAlert = styled.div`
  display: flex;
  align-items: center;

  div {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${redAlertColor};
    margin-right: 10px;
  }
`;

export const Hour = styled.p`
  font-weight: 600;
  font-size: 44px;
`;

export const ExtensiveDate = styled.p`
  font-weight: 500;
  font-size: 32px;
`;
