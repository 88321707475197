import { Switch, Route } from "react-router-dom";
import Operation from "../pages/Operation";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Operation} />
    </Switch>
  );
}
