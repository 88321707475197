export const getCurrentHour = (): string => {
  const newDate = new Date();

  const formatedHour = newDate.getHours();
  const formatedMinutes = newDate.getMinutes();

  let formatedTimeMinutes;

  formatedMinutes.toString().length === 1
    ? (formatedTimeMinutes = `${formatedHour}:0${formatedMinutes}`)
    : (formatedTimeMinutes = `${formatedHour}:${formatedMinutes}`);

  return formatedTimeMinutes;
};

export const getCurrentDate = (): string => {
  const newDate = new Date();

  const day = newDate.getDate();
  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ][newDate.getMonth()];
  const year = newDate.getFullYear();

  return `${day} de ${month} de ${year}`;
};
