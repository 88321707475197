import { createGlobalStyle } from "styled-components";
import { backgroundColorDefault, fontColorDefault } from "./colors";

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0px;
        padding: 0px;
    }

    body {
        background-color: ${backgroundColorDefault};
        color: ${fontColorDefault};
        font-family: 'Montserrat', sans-serif;
        
    }
`;

export default GlobalStyle;
